import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box, Chip, ChipProps, Typography } from '@mui/material';
import { TestAdd as TestAddIcon } from '@front/icon';
import { ResponsiveTooltip, useBaseRightPanel } from '@front/ui';
import TooltipList from '@lib/ia/src/components/TooltipList';
import TooltipListItem from '@lib/ia/src/components/TooltipListItem';

import CreateQuizContext from '../../context';
import { CreateQuizPanelKeys } from '../../enums';
import { CreateQuizFormValue } from '../../type';

import Section from './Section';

const styles = {
  root: {
    minWidth: 0,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    pt: 1,
  },
  more: {
    lineHeight: '40px',
    opacity: 0.5,
  },
};

type TagByCategoryType = {
  color: ChipProps['color'];
  type: string;
  display: string;
  shortDisplay: string;
  count?: number;
  total?: number;
  icon: string;
};
export default function TopicCard() {
  const { t } = useTranslation('quiz');
  const { openRightPanel } = useBaseRightPanel();
  const [{ tagMap, panelKeyPrefix, disabledParts }] =
    useContext(CreateQuizContext);
  const { watch } = useFormContext<CreateQuizFormValue>();
  const tags = watch('tags', []);
  const panelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.Topic}`;
  const disabled =
    disabledParts.includes('all') || disabledParts.includes('topics');
  const organizedTags = useMemo(() => {
    const tagsByCategory = tags.reduce(
      (acc, tagCode) => {
        const tag = tagMap[tagCode];
        if (!tag) return acc;
        const display = tag.name;
        const shortDisplay =
          display.length > 28 ? `${display.slice(0, 28)}...` : display;
        if (tag.isMistake) {
          acc.mistake.push({
            color: 'error',
            type: 'mistake',
            display,
            shortDisplay,
            icon: 'ActionCloseThick',
            count:
              'questionCount' in tag
                ? tag.practicedCount - tag.correctCount
                : undefined,
            total: 'questionCount' in tag ? tag.questionCount : undefined,
          });
        }
        if (tag.isOvertime) {
          acc.overtime.push({
            color: 'warning',
            type: 'overtime',
            display,
            shortDisplay,
            icon: 'TestClockSolid',
            count: 'questionCount' in tag ? tag.overtimeCount : undefined,
            total: 'questionCount' in tag ? tag.questionCount : undefined,
          });
        }
        if (tag.isOutOfPractice) {
          acc.notPracticed.push({
            color: 'primary',
            type: 'notPracticed',
            display,
            shortDisplay,
            icon: 'TestOutOfPractice',
            count:
              'questionCount' in tag
                ? tag.questionCount - tag.practicedCount
                : undefined,
            total: 'questionCount' in tag ? tag.questionCount : undefined,
          });
        }

        return acc;
      },
      {
        mistake: [] as TagByCategoryType[],
        overtime: [] as TagByCategoryType[],
        notPracticed: [] as TagByCategoryType[],
      }
    );
    return [
      ...tagsByCategory.mistake,
      ...tagsByCategory.overtime,
      ...tagsByCategory.notPracticed,
    ];
  }, [tagMap, tags]);

  const restCount = organizedTags.length > 3 ? organizedTags.length - 3 : 0;

  return (
    <Section title={t('createQuiz.topic.title', 'Topic')}>
      <Box sx={styles.root}>
        <ResponsiveTooltip
          titleIcon={<TestAddIcon width={16} height={16} />}
          title={t('createQuiz.topic.customize.hint.title', 'Customize Topics')}
          content={t(
            'createQuiz.topic.customize.hint.content',
            'Select topics to filter and practice questions specifically tagged for focused practice.'
          )}
        >
          <Chip
            variant="dashed"
            label={t('createQuiz.topic.customize.title')}
            icon={<TestAddIcon width={16} height={16} />}
            clickable
            disabled={disabled}
            onClick={() => openRightPanel(panelKey)}
          />
        </ResponsiveTooltip>
      </Box>
      {organizedTags.length > 0 && (
        <Box sx={styles.chips}>
          {organizedTags.slice(0, 3).map((tag) => (
            <ResponsiveTooltip
              key={`${tag.type} ${tag.display}`}
              title={`#${tag.shortDisplay}`}
              content={
                <TooltipList
                  content={t('createQuiz.topic.customize.hint.content', {
                    tag: `#${tag.shortDisplay}`,
                    context: tag.type,
                  })}
                >
                  {tag.count !== undefined && (
                    <TooltipListItem
                      name={{
                        icon: tag.icon,
                        text: t(
                          `createQuiz.topic.customize.property.${tag.type}`
                        ),
                      }}
                      value={{
                        type: 'text',
                        text: tag.count ? `${tag.count}` : '',
                      }}
                    />
                  )}
                  {tag.total !== undefined && (
                    <TooltipListItem
                      name={{
                        icon: 'ChatQuestionList',
                        text: t(
                          'createQuiz.topic.customize.property.totalQuestion',
                          'Total Questions'
                        ),
                      }}
                      value={{ type: 'text', text: `${tag.total}` }}
                    />
                  )}
                </TooltipList>
              }
            >
              <Chip
                variant="filled"
                color={tag.color}
                clickable
                label={
                  tag.count === undefined
                    ? `#${tag.display}`
                    : `#${tag.display}(${tag.count})`
                }
                onClick={() => openRightPanel(panelKey)}
                disabled={disabled}
              />
            </ResponsiveTooltip>
          ))}
          {restCount > 0 && (
            <Typography sx={styles.more} variant="body2">
              + {restCount} more
            </Typography>
          )}
        </Box>
      )}
    </Section>
  );
}
