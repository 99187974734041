import { useFormContext } from 'react-hook-form';
import { Box, Divider } from '@mui/material';
import { ExamMode } from '@lib/web/apis';

import { CreateQuizFormValue } from '../type';

import AdvancedSection from './parts/AdvancedSection';
import BottomActions from './parts/BottomActions';
import ChallengeSection from './parts/ChallengeSection';
import DifficultySection from './parts/DifficultySection';
import DurationSection from './parts/DurationSection';
import FixedSettingsSection from './parts/FixedSettingsSection';
import ModeSection from './parts/ModeSection';
import StartQuizPaidFutureBlocker from './parts/StartQuizPaidFutureBlocker';
import TimerVisibilitySection from './parts/TimerVisibilitySection';
import TopicSection from './parts/TopicSection';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2.5,
    height: '100%',
  },
};

export type CreateQuizFormProps = {
  sectionId: string;
  maxQuestionCount: number;
  minQuestionCount: number;
  officialQuestionCount: number;
};
export default function CreateQuizForm({
  variant,
}: {
  variant: 'page' | 'panel';
}) {
  const { watch } = useFormContext<CreateQuizFormValue>();
  const mode = watch('mode');
  const isMock = mode === ExamMode.MockExam;
  const inPanel = variant === 'panel';
  const placement = variant === 'page' ? 'center' : 'rhs';
  if (isMock) {
    return (
      <Box sx={styles.root}>
        <ModeSection />
        <Divider />
        <ChallengeSection />
        <Divider />
        <TimerVisibilitySection placement={placement} />
        <Divider />
        <FixedSettingsSection />
        <StartQuizPaidFutureBlocker placement={placement} />
        {inPanel && <BottomActions />}
      </Box>
    );
  }

  return (
    <Box sx={styles.root}>
      <ModeSection />
      <Divider />
      <DurationSection />
      <Divider />
      <DifficultySection placement={placement} />
      <Divider />
      <TopicSection />
      <Divider />
      <ChallengeSection />
      <Divider />
      <AdvancedSection />
      <StartQuizPaidFutureBlocker placement={inPanel ? 'rhs' : 'auto'} />
      {inPanel && <BottomActions />}
    </Box>
  );
}
