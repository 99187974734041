import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '@mui/material';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { LeagueTier, useClubLeagueInfo } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { Emoji } from '@lib/web/ui';
import { getLeagueName } from '@lib/web/utils';
import { differenceInDays } from 'date-fns';

import useLeagueRankingList from '../hooks/useLeagueRankingList';

import LeagueBadges from './LeagueBadges';
import LeagueRankingList from './LeagueRankingList';
import RankingListHeader from './RankingListHeader';
import TabArea from './RankingTabArea';

const styles = {
  leagueBadges: {
    px: {
      xs: '20px',
      md: '6px',
    },
  },
  currentLeague: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    px: {
      xs: '20px',
      md: '12px',
    },
  },
  currentLeagueSkeleton: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    px: {
      xs: '20px',
      md: '12px',
    },
  },

  empty: {
    px: '12px',
    display: 'flex',
    flexDirection: 'column',
    '& svg': {
      width: { xs: 88, md: 110 },
      height: { xs: 160, md: 200 },
    },
  },
  emptyContent: {
    opacity: 0.5,
  },
  currentLeagueContent: {
    opacity: 0.75,
  },
};

function CurrentLeagueSkeleton() {
  return (
    <Box sx={styles.currentLeagueSkeleton}>
      <Skeleton width={170} height={31} />
      <Skeleton width="100%" height={24} />
      <Skeleton width={50} height={24} />
    </Box>
  );
}

type CurrentLeagueProps = {
  isEmpty: boolean;
  isLoading: boolean;
  currentTier?: LeagueTier;
};

function CurrentLeague({
  isEmpty,
  isLoading,
  currentTier,
}: CurrentLeagueProps) {
  const { t } = useTranslation('club');
  const clubSlug = useClubSlug();

  const { data } = useClubLeagueInfo(clubSlug);

  if (isLoading) return <CurrentLeagueSkeleton />;

  const daysLeft = data?.data.endAt
    ? differenceInDays(new Date(data.data.endAt), new Date())
    : 0;

  return (
    <Box sx={styles.currentLeague}>
      <Typography variant="appH2" fontWeight={700} fontSize={24}>
        {t('## League', {
          name: getLeagueName(currentTier || LeagueTier.Alpha),
        })}
      </Typography>
      {!isEmpty && (
        <>
          <Typography variant="body1" sx={styles.currentLeagueContent}>
            {t('Top ## advance to the next league', { count: 10 })}
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={500}
            color="primary.light"
          >
            {daysLeft} days
          </Typography>
        </>
      )}
      {isEmpty && (
        <Typography variant="body1" sx={styles.currentLeagueContent}>
          {t('Compete against students in a weekly leaderboard')}
        </Typography>
      )}
    </Box>
  );
}

function EmptyState() {
  const { t } = useTranslation('club');
  return (
    <Box sx={styles.empty}>
      <Emoji variant="alien" />
      <Typography sx={styles.emptyContent} variant="body1">
        {t('Complete a practice to join this week league')}
      </Typography>
    </Box>
  );
}

export default function LeagueRankingTabContent() {
  const userId = useCurrentUserId();
  const { data, error } = useLeagueRankingList();

  const isEmpty = (data?.data && data.data.length === 0) || !!error;
  const currentTier = data?.data.find(
    (item) => item.userId === userId
  )?.leagueTier;
  const loaded = !!data || !!error;

  return (
    <TabArea>
      <TabArea.Header>
        <Box sx={styles.leagueBadges}>
          <LeagueBadges
            currentTier={loaded ? currentTier || LeagueTier.Alpha : undefined}
          />
        </Box>
        <CurrentLeague
          isEmpty={isEmpty}
          isLoading={!loaded}
          currentTier={loaded ? currentTier || LeagueTier.Alpha : undefined}
        />
        {data && !isEmpty && <RankingListHeader />}
      </TabArea.Header>

      <TabArea.EmptyState visible={isEmpty}>
        <EmptyState />
      </TabArea.EmptyState>

      <TabArea.Scrollbar visible={!isEmpty}>
        <LeagueRankingList items={data?.data || []} />
      </TabArea.Scrollbar>
    </TabArea>
  );
}
