import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLatestValueRef } from '@front/helper';
import { cloneDeep, isEqual } from 'lodash';

import {
  ChallengerIconListLayoutItemObj,
  ChallengerIconListLayoutItemObjMap,
  CreateQuizFormPartTypes,
  MarkItemObjMap,
  TagItemObjMap,
} from './type';

export interface CreateQuizValue {
  availableQuestionCount?: number;
  officialQuestionCount: number;
  minQuestionCount: number;
  maxQuestionCount: number;
  disabledParts: CreateQuizFormPartTypes[];
  challengerMap: ChallengerIconListLayoutItemObjMap;
  tagMap: TagItemObjMap;
  markMap: MarkItemObjMap;
  variant: 'page' | 'panel';
  panelKeyPrefix?: string;
  challengeId?: string;
  challengeMode?: boolean;
}

export const initialValue = {
  disabledParts: [],
  challengerMap: {},
  tagMap: {},
  markMap: {},
  officialQuestionCount: 1,
  minQuestionCount: 1,
  maxQuestionCount: 1,
  variant: 'page' as const,
  panelKeyPrefix: '',
  challengeMode: false,
};

export type CreateQuizContextValue = [
  CreateQuizValue,
  Dispatch<SetStateAction<CreateQuizValue>>
];

function sourceToMap<T>(source: T[] | undefined, property: keyof T) {
  if (!source) return {};
  return source.reduce((acc, cur) => {
    return {
      ...acc,
      [String(cur[property])]: cur,
    };
  }, {});
}

const CreateQuizContext = createContext<CreateQuizContextValue>([
  initialValue,
  () => {},
]);

export type CreateQuizProviderProps = PropsWithChildren<{
  variant: 'page' | 'panel';
  challengeId?: string;
  challengerSource?: ChallengerIconListLayoutItemObj[];
  tagSource?: (GetExamSectionTagsRes | GetQuizHashtagsRes)[];
  markSource?: GetIaMarkRhsDefaultViewRes[];
  panelKeyPrefix?: string;
  disabledParts?: CreateQuizFormPartTypes[];
  challengeMode?: boolean;
}>;

export function CreateQuizProvider({
  children,
  ...rest
}: PropsWithChildren<CreateQuizProviderProps>) {
  const [value, setValue] = useState<CreateQuizValue>(initialValue);
  const previousValue = useRef({});
  const latestValue = useLatestValueRef(rest);

  const hasSameProps = isEqual(previousValue.current, latestValue.current);

  useEffect(() => {
    if (hasSameProps) return;
    const {
      variant,
      challengerSource,
      tagSource,
      markSource,
      panelKeyPrefix = '',
      disabledParts = [],
      challengeId,
      challengeMode = false,
    } = latestValue.current;

    setValue((st) => ({
      ...st,
      variant,
      panelKeyPrefix,
      challengerMap: {
        ...st.challengerMap,
        ...sourceToMap(challengerSource, 'id'),
      },
      tagMap: {
        ...st.tagMap,
        ...sourceToMap(tagSource, 'code'),
      },
      markMap: {
        ...st.markMap,
        ...sourceToMap(markSource, 'code'),
      },
      disabledParts,
      challengeId,
      challengeMode,
    }));
    previousValue.current = cloneDeep(latestValue.current);
  }, [hasSameProps, latestValue, rest]);

  return (
    <CreateQuizContext.Provider value={[value, setValue]}>
      {children}
    </CreateQuizContext.Provider>
  );
}

export default CreateQuizContext;
