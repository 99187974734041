import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Switch, Typography } from '@mui/material';
import { OtherIncognito as OtherIncognitoIcon } from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';

import useStartQuizBlocker from '../../hooks/useStartQuizBlocker';
import { Placement } from '../../type';

import { StartQuizBlockerType } from './StartQuizPaidFutureBlocker';

const styles = {
  root: (placement: Placement) => ({
    gap: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      md: placement === 'center' ? 'row-reverse' : 'row',
    },
    justifyContent: {
      xs: 'space-between',
      md: placement === 'center' ? 'flex-end' : 'space-between',
    },
    width: {
      xs: '100%',
      md: placement === 'center' ? 'unset' : '100%',
    },
  }),
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '2.5px',
  },
  switch: {
    position: 'relative',
    display: 'flex',
  },
  blockerOverlay: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

type IncognitoModeSwitchProps = {
  placement: Placement;
  disabled?: boolean;
};

export default function IncognitoModeSwitch({
  placement,
  disabled,
}: IncognitoModeSwitchProps) {
  const { t } = useTranslation('quiz');
  const { showBlocker, isFeatureBlocked, isLoading } =
    useStartQuizBlocker(placement);

  const { watch, setValue } = useFormContext();
  const isIncognito = watch('isIncognito');
  const toggleIncognito = () => {
    setValue('isIncognito', !isIncognito, { shouldDirty: true });
  };

  return (
    <ResponsiveTooltip
      titleIcon={<OtherIncognitoIcon width={16} height={16} />}
      title={t('createQuiz.settings.incognito.hint.title', 'Incognito Mode')}
      content={t(
        'createQuiz.settings.incognito.hint.content',
        'Hide activities from followers.'
      )}
    >
      <Box sx={styles.root(placement)}>
        <Box sx={styles.title}>
          <OtherIncognitoIcon width={16} height={16} />
          <Typography variant="body2">
            {t('createQuiz.settings.incognito.label', {
              context: isIncognito ? 'open' : 'close',
            })}
          </Typography>
        </Box>
        <Box sx={styles.switch}>
          <Switch
            checked={!!isIncognito}
            onClick={toggleIncognito}
            disabled={isLoading || disabled}
          />
          {isFeatureBlocked && (
            <Box
              onClick={() => showBlocker(StartQuizBlockerType.IncognitoMode)}
              sx={styles.blockerOverlay}
            />
          )}
        </Box>
      </Box>
    </ResponsiveTooltip>
  );
}
