import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userToChallengerItem } from '@app/web/src/components/CreateQuiz/components/panels/CreateQuizChallengersPanel/useCreateQuizChallengersDataset';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import { EmphasizeButtonProps, IconButtonProps } from '@front/ui';
import { apis } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

import CreateQuizPanelSimpleButton from '../CreateQuiz/CreateQuizPanelSimpleButton';
import { ChallengerIconListLayoutItemObj } from '../CreateQuiz/type';

type ChallengeButtonProps = {
  userId?: string;
  panelKeyPrefix?: string;
} & (
  | ({
      showIconOnly?: false;
    } & Omit<EmphasizeButtonProps, 'children'>)
  | ({
      showIconOnly: true;
    } & Omit<IconButtonProps, 'children'>)
);

export default function ChallengeButton({
  userId,
  onClick,
  onMouseDown,
  showIconOnly,
  sx,
  panelKeyPrefix,
}: ChallengeButtonProps) {
  const { t } = useTranslation();
  const clubSlug = useClubSlug();
  const [challengerSource, setChallengerSource] = useState<
    ChallengerIconListLayoutItemObj[]
  >([]);
  const [loading, setLoading] = useState(false);
  if (!userId || !clubSlug) return null;

  const handleChallengeClick = async (e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    setLoading(true);
    const [res] = await call(apis.member.getSocialProfile(userId));
    const socialData = res?.data;
    setLoading(false);

    if (!socialData) return;

    setChallengerSource([
      userToChallengerItem({
        user: {
          userId: socialData.userId,
          userName: socialData.userName,
          displayName: socialData.fullName,
          nftAvatar: socialData.avatarUrl,
          avatar: socialData.photoUrl,
          indicator: socialData.indicator,
        },
        disabledUpdate: true,
      }),
    ]);
  };

  return (
    <CreateQuizPanelSimpleButton
      sx={sx}
      prefixIcon={<MainChallengeIcon />}
      showIconOnly={showIconOnly}
      withPanel
      challengerSource={challengerSource}
      loading={loading}
      defaultValues={{ challengers: [userId] }}
      onBeforeOpenPanel={handleChallengeClick}
      panelKeyPrefix={panelKeyPrefix}
      onMouseDown={onMouseDown}
    >
      {t('button.Challenge')}
    </CreateQuizPanelSimpleButton>
  );
}
