import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import PanelContent from '@app/web/src/components/PanelContent';
import {
  ActionClear as ActionClearIcon,
  ProfileSetting as ProfileSettingIcon,
} from '@front/icon';
import { BaseLayoutRightPanel, TipButton } from '@front/ui';

import CreateQuizContext from '../../context';
import useClosePanel from '../../hooks/useClosePanel';
import { CreateQuizFormValue } from '../../type';
import IncognitoModeSwitch from '../parts/IncognitoModeSwitch';
import Section from '../parts/Section';
import TimerSwitch from '../parts/TimerSwitch';

const styles = {
  container: {
    pt: 1,
    pb: '12px',
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  tags: {
    pt: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  hideTag: {
    display: 'none',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  resetButton: {
    marginLeft: 'auto',
  },
  emojis: {
    pt: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  iconButton: {
    width: '26px',
    height: '26px',
  },
};

export default function AdvancedSettingsPanel() {
  const { t } = useTranslation(['quiz', 'common']);
  const { register, resetField, getFieldState } =
    useFormContext<CreateQuizFormValue>();
  const [createQuizSettings] = useContext(CreateQuizContext);
  const disabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('settings');

  const isOpenTimerDirty = getFieldState('isOpenTimer').isDirty;
  const isIncognitoDirty = getFieldState('isIncognito').isDirty;

  const clearSettings = () => {
    register('isOpenTimer');
    register('isIncognito');
    resetField('isOpenTimer');
    resetField('isIncognito');
  };
  const handleClosePanel = useClosePanel();

  return (
    <BaseLayoutRightPanel
      titleIcon={<ProfileSettingIcon width="16" height="16" />}
      title={t('createQuiz.settings.title', 'Advanced Settings')}
      toolComponent={
        (isOpenTimerDirty || isIncognitoDirty) && !disabled ? (
          <TipButton
            title={t('toolbar.Reset')}
            onClick={clearSettings}
            sx={styles.resetButton}
          >
            <ActionClearIcon />
          </TipButton>
        ) : undefined
      }
      onIconClick={handleClosePanel}
    >
      <PanelContent>
        <Section
          title={t('createQuiz.settings.timer.title', 'Timer & Visibility')}
        >
          <Box display="grid" gap={1}>
            <TimerSwitch placement="rhs" disabled={disabled} />
            <IncognitoModeSwitch placement="rhs" disabled={disabled} />
          </Box>
        </Section>
      </PanelContent>
    </BaseLayoutRightPanel>
  );
}
