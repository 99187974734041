import { useCallback } from 'react';
import Router from 'next/router';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import { useBaseRightPanel } from '@front/ui';

import { Placement, StartQuizBlockerType } from '../type';

function validateAndGetBlockerType(val?: string) {
  if (val && (Object.values(StartQuizBlockerType) as string[]).includes(val))
    return val as StartQuizBlockerType;
}

function useStartQuizBlockerType(placement: Placement) {
  const { rightPanelParams } = useBaseRightPanel();

  if (placement === 'center') {
    const block = Router.query.block as string;
    return validateAndGetBlockerType(block);
  }

  const { block } = rightPanelParams;
  return validateAndGetBlockerType(Router.query.block as string) || block;
}

export default function useStartQuizBlocker(placement: Placement) {
  const { isFreeUser, isLoading } = useMemberSubscription();

  const { setRightParams } = useBaseRightPanel();
  const blockerType = useStartQuizBlockerType(placement);

  const showBlockerOnPage = useCallback((type: StartQuizBlockerType) => {
    const { pathname, query, push } = Router;
    push({ pathname, query: { ...query, block: type } }, undefined, {
      shallow: true,
    });
  }, []);

  const showBlockerOnRhs = useCallback(
    (type: StartQuizBlockerType) => {
      setRightParams({ block: type });
    },
    [setRightParams]
  );

  const closeBlockerOnPage = useCallback(() => {
    const { pathname, query, push } = Router;
    const newQuery = Object.keys(query).reduce((acc, key) => {
      if (key !== 'block') {
        acc[key] = query[key] as string;
      }
      return acc;
    }, {} as Record<string, string>);
    push({ pathname, query: newQuery }, undefined, {
      shallow: true,
    });
  }, []);

  const closeBlockerOnRhs = useCallback(() => {
    setRightParams({ block: undefined });
  }, [setRightParams]);

  const showBlocker =
    placement === 'center' ? showBlockerOnPage : showBlockerOnRhs;

  const closeBlocker =
    placement === 'center' ? closeBlockerOnPage : closeBlockerOnRhs;
  return {
    blockerType,
    isLoading,
    showBlocker,
    closeBlocker,
    isFeatureBlocked: isFreeUser,
  };
}
