import { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  MainChallenge as MainChallengeIcon,
  OtherPlay as OtherPlayIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanelPortal,
  EmphasizeButton,
  useBaseLayout,
} from '@front/ui';

import { PrivatePanelKeys } from '../../types/panel';
import AuthGuard from '../AuthGuard';

import CreateQuizByPanel, { CreateQuizByPanelProps } from './CreateQuizByPanel';

type CreateQuizPanelSimpleButtonProps = PropsWithChildren<{
  sx?: SxProps<Theme>;
  loading?: boolean;
  showIconOnly?: boolean;
  panelKeyPrefix?: string;
  prefixIcon?: ReactNode;
  onBeforeOpenPanel?: (
    e: MouseEvent<HTMLButtonElement>
  ) => Promise<void> | void;
  onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void;
  renderButton?: (props: {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  }) => ReactNode;
}>;

function CreateQuizPanelSimpleButton({
  panelKeyPrefix = '',
  children,
  loading,
  showIconOnly,
  prefixIcon,
  sx,
  onBeforeOpenPanel,
  onMouseDown,
  renderButton,
}: CreateQuizPanelSimpleButtonProps) {
  const { t } = useTranslation('quiz');
  const { openRightPanel } = useBaseLayout();

  const openQuizPanel = async (e: MouseEvent<HTMLButtonElement>) => {
    if (onBeforeOpenPanel) await onBeforeOpenPanel(e);
    openRightPanel(
      `${panelKeyPrefix}${PrivatePanelKeys.CreateQuiz}`,
      (params) => params
    );
  };

  return (
    <AuthGuard>
      {renderButton ? (
        renderButton({ onClick: openQuizPanel })
      ) : showIconOnly ? (
        <IconButton onClick={openQuizPanel} onMouseDown={onMouseDown}>
          <MainChallengeIcon />
        </IconButton>
      ) : (
        <EmphasizeButton
          sx={sx}
          prefixIcon={prefixIcon || <OtherPlayIcon />}
          onClick={openQuizPanel}
          onMouseDown={onMouseDown}
          loading={loading}
        >
          {children || t('button.Start')}
        </EmphasizeButton>
      )}
    </AuthGuard>
  );
}

export type CreateQuizPanelSimpleButtonRootProps =
  CreateQuizPanelSimpleButtonProps &
    CreateQuizByPanelProps & { withPanel?: boolean };

export default function CreateQuizPanelSimpleButtonRoot({
  withPanel = false,
  loading = false,
  prefixIcon,
  children,
  sx,
  showIconOnly,
  onBeforeOpenPanel,
  onMouseDown,
  renderButton,
  ...rest
}: CreateQuizPanelSimpleButtonRootProps) {
  return (
    <>
      <CreateQuizPanelSimpleButton
        sx={sx}
        showIconOnly={showIconOnly}
        panelKeyPrefix={rest.panelKeyPrefix}
        prefixIcon={prefixIcon}
        loading={loading}
        onBeforeOpenPanel={onBeforeOpenPanel}
        onMouseDown={onMouseDown}
        renderButton={renderButton}
      >
        {children}
      </CreateQuizPanelSimpleButton>
      {withPanel && !loading && (
        <BaseLayoutRightPanelPortal>
          <CreateQuizByPanel {...rest} />
        </BaseLayoutRightPanelPortal>
      )}
    </>
  );
}
