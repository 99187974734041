import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import {
  ChatQuestionList as ChatQuestionListIcon,
  EditorDifficultyMedium as EditorDifficultyMediumIcon,
} from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';
import { useCurrentExam } from '@lib/web/hooks';

import CreateQuizContext from '../../context';

import Section from './Section';
import SectionItem from './SectionItem';

export default function FixedSettingsSection() {
  const { t } = useTranslation('quiz');
  const [createQuizSettings] = useContext(CreateQuizContext);
  const { section } = useCurrentExam();
  return (
    <Section title={t('createQuiz.mock.fixed.title', 'Fixed Settings:')}>
      <Box>
        <ResponsiveTooltip
          titleIcon={<ChatQuestionListIcon width={16} height={16} />}
          title={t('createQuiz.mock.duration.hint.title')}
          content={t('createQuiz.mock.duration.hint.content', {
            questions: createQuizSettings.officialQuestionCount,
            minutes: section?.timeMinutes ?? 0,
          })}
          tooltipProps={{ followCursor: true }}
        >
          <SectionItem
            display={t('createQuiz.mock.duration.title')}
            content={t('createQuiz.mock.duration.content', {
              questions: createQuizSettings.officialQuestionCount,
              minutes: section?.timeMinutes ?? 0,
            })}
            icon={<ChatQuestionListIcon width={19.2} height={19.2} />}
            disabled
          />
        </ResponsiveTooltip>
        <ResponsiveTooltip
          titleIcon={<EditorDifficultyMediumIcon width={16} height={16} />}
          title={t('createQuiz.mock.difficulty.hint.title')}
          content={t('createQuiz.mock.difficulty.hint.content')}
          tooltipProps={{ followCursor: true }}
        >
          <SectionItem
            display={t('createQuiz.mock.difficulty.title')}
            content={t('createQuiz.mock.difficulty.content')}
            icon={<EditorDifficultyMediumIcon width={19.2} height={19.2} />}
            disabled
          />
        </ResponsiveTooltip>
      </Box>
    </Section>
  );
}
