import { useMemo } from 'react';
import { MessageItemEvent } from '@lib/ia/src/layouts/ChannelLayout/types';
import {
  AgentComposingStatus,
  StreamChatGenerics,
} from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import GeneralMessage from '../GeneralMessage';

type AgentMessageProps = {
  channel: Channel;
  message: StreamMessage<StreamChatGenerics>;
  messages: StreamMessage<StreamChatGenerics>[];
  childChannel: Channel | undefined;
  availableEventNames?: string[];
};

export default function AgentMessage({
  channel,
  message,
  messages,
  childChannel,
  availableEventNames = [],
}: AgentMessageProps) {
  const availableBotEventNames = useMemo(() => {
    if (
      message.agentComposingStatus &&
      message.agentComposingStatus !== AgentComposingStatus.Completed
    ) {
      return [];
    }

    return availableEventNames;
  }, [availableEventNames, message.agentComposingStatus]);

  const extraActionBarEvents = useMemo<MessageItemEvent[]>(() => {
    if (
      message.agentComposingStatus &&
      message.agentComposingStatus !== AgentComposingStatus.Completed
    ) {
      return [];
    }

    const botSpecificEvents: MessageItemEvent[] = [
      {
        type: 'event',
        value: 'insert',
        text: 'Insert',
        icon: 'EditorInsertBelow',
        metadata: {
          message,
        },
      },
      {
        type: 'event',
        value: 'insertBelow',
        text: 'Insert Below',
        icon: 'EditorInsertBelow',
        metadata: {
          message,
        },
      },
      {
        type: 'event',
        value: 'replaceSelection',
        text: 'Replace Selection',
        icon: 'OtherResend',
        metadata: {
          message,
        },
      },
    ];

    const allEvents = [...botSpecificEvents];

    return allEvents.filter((event) =>
      availableEventNames.includes(event.value)
    );
  }, [availableEventNames, message]);

  return (
    <GeneralMessage
      channel={channel}
      message={message}
      messages={messages}
      childChannel={childChannel}
      availableEventNames={availableBotEventNames}
      extraActionBarEvents={extraActionBarEvents}
    />
  );
}
