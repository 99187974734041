import { useCallback } from 'react';

import {
  setKeyword as setKeywordFn,
  setVisible,
} from '../../reducers/searchReducer';
import { useAppDispatch, useAppSelector } from '../redux';

export function useIaSearchActions() {
  const dispatch = useAppDispatch();
  const search = useAppSelector((st) => st.search);

  const toggleSearch = useCallback(() => {
    dispatch(setKeywordFn(''));
    dispatch(setVisible(!search.visible));
  }, [dispatch, search?.visible]);

  const hideSearch = useCallback(() => {
    dispatch(setKeywordFn(''));
    dispatch(setVisible(false));
  }, [dispatch]);

  const showSearch = useCallback(() => {
    dispatch(setKeywordFn(''));
    dispatch(setVisible(true));
  }, [dispatch]);

  const setKeyword = useCallback(
    (keyword?: string) => {
      dispatch(setKeywordFn(keyword));
    },
    [dispatch]
  );

  const resetToDefault = useCallback(() => {
    dispatch(setKeywordFn(''));
    dispatch(setVisible(false));
  }, [dispatch]);

  return {
    setKeyword,
    showSearch,
    hideSearch,
    toggleSearch,
    resetToDefault,
  };
}
