import { ReactNode, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import PanelContent from '@app/web/src/components/PanelContent';
import {
  ActionClear as ActionClearIcon,
  ChatHashtag as ChatHashtagIcon,
} from '@front/icon';
import {
  Accordion,
  BaseLayoutRightPanel,
  Tag,
  TagProps,
  TipButton,
} from '@front/ui';
import { useExamSectionTags } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';

import CreateQuizContext from '../../context';
import useClosePanel from '../../hooks/useClosePanel';
import { CreateQuizFormValue } from '../../type';

const styles = {
  container: {
    pt: 1,
    pb: '12px',
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  tags: {
    pt: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  hideTag: {
    display: 'none',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  resetButton: {
    marginLeft: 'auto',
  },
  emojis: {
    pt: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  iconButton: {
    width: '26px',
    height: '26px',
  },
};

function LoadingSkeleton() {
  return (
    <>
      <Skeleton height={60} />
      <Skeleton />
      <Skeleton />
    </>
  );
}

type TagsProps = {
  title: string;
  tags: GetExamSectionTagsRes[];
  color: TagProps['color'];
  onClick: (tag: GetExamSectionTagsRes) => void;
  icon?: ReactNode;
  disabled?: boolean;
};

function Tags({ title, tags, color, disabled, icon, onClick }: TagsProps) {
  const { t } = useTranslation('quiz');
  const { watch } = useFormContext<CreateQuizFormValue>();
  const tagValue = watch('tags', []);
  const count = tags.filter((tag) => tagValue.includes(tag.code)).length;
  return (
    <div>
      <Accordion title={`${title} (${count})`} titleIcon={icon}>
        <Box sx={styles.tags}>
          {tags.map((tag) => {
            const selected = tagValue.some((code) => code === tag.code);
            const tagDisabled =
              !tag.questionCount || tag.questionCount === 0 || disabled;
            return (
              <Tag
                key={tag.id}
                label={tag.name}
                color={color}
                onClick={() => onClick(tag)}
                selected={selected}
                disabled={tagDisabled}
              />
            );
          })}
          {tags.length === 0 && (
            <Typography variant="body2" color="grey.500">
              {t('Practice more problems to see past ## topic', {
                title: title.toLowerCase(),
              })}
            </Typography>
          )}
        </Box>
      </Accordion>
    </div>
  );
}

export default function CreateQuizHashtagsPanel() {
  const { t } = useTranslation(['quiz', 'common']);
  const { sectionId } = useCurrentIaClub();
  const handleClosePanel = useClosePanel();
  const { watch, register, setValue, resetField, getFieldState } =
    useFormContext<CreateQuizFormValue>();

  const [createQuizSettings, setCreateQuizSettings] =
    useContext(CreateQuizContext);
  const hashtagsDisabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('topics');
  const isTagsDirty = getFieldState('tags').isDirty;
  const tagsValue = watch('tags');

  const { data } = useExamSectionTags(sectionId);

  const tagsMap = useMemo(() => {
    if (!data)
      return {
        mistakes: [] as GetExamSectionTagsRes[],
        overtime: [] as GetExamSectionTagsRes[],
        outOfPractices: [] as GetExamSectionTagsRes[],
      };

    const mistakes: GetExamSectionTagsRes[] = [];
    const overtime: GetExamSectionTagsRes[] = [];
    const outOfPractices: GetExamSectionTagsRes[] = [];

    data.data.items.forEach((item) => {
      if (item.isMistake) mistakes.push(item);
      if (item.isOvertime) overtime.push(item);
      if (item.isOutOfPractice) outOfPractices.push(item);
    });

    return {
      mistakes,
      overtime,
      outOfPractices,
    };
  }, [data]);

  const handleTagClick = (tag: GetExamSectionTagsRes) => {
    if (tagsValue.some((code) => code === tag.code)) {
      setValue(
        'tags',
        tagsValue.filter((code) => code !== tag.code),
        { shouldDirty: true }
      );

      trackEvent('form', {
        elementName: 'hashtag',
        action: 'remove',
      });
    } else {
      setValue('tags', [...tagsValue, tag.code], { shouldDirty: true });
      trackEvent('form', {
        elementName: 'hashtag',
        action: 'add',
      });
    }

    setCreateQuizSettings((st) => ({
      ...st,
      tagMap: {
        ...st.tagMap,
        [tag.code]: tag,
      },
    }));
  };

  const clearHashtagsAndEmojis = () => {
    register('tags');
    resetField('tags');
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={<ChatHashtagIcon width="16" height="16" />}
      title={t('panel.Hashtags & Reactions')}
      toolComponent={
        isTagsDirty && !hashtagsDisabled ? (
          <TipButton
            title={t('toolbar.Reset')}
            onClick={clearHashtagsAndEmojis}
            sx={styles.resetButton}
          >
            <ActionClearIcon />
          </TipButton>
        ) : undefined
      }
      onIconClick={handleClosePanel}
    >
      <PanelContent>
        <Box sx={styles.container}>
          {!data && <LoadingSkeleton />}
          {!!data && (
            <Box sx={styles.tagsContainer}>
              <Tags
                title={t('Mistakes')}
                tags={tagsMap.mistakes}
                color="error"
                onClick={handleTagClick}
                disabled={hashtagsDisabled}
              />
              <Tags
                title={t('Overtime')}
                tags={tagsMap.overtime}
                color="warning"
                onClick={handleTagClick}
                disabled={hashtagsDisabled}
              />
              <Tags
                title={t('Have Not Practiced Yet', 'Haven’t Practiced Yet')}
                tags={tagsMap.outOfPractices}
                color="primary"
                onClick={handleTagClick}
                disabled={hashtagsDisabled}
              />
            </Box>
          )}
        </Box>
      </PanelContent>
    </BaseLayoutRightPanel>
  );
}
