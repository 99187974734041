import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CreateQuizPanelButton from '@app/web/src/components/CreateQuiz/CreateQuizPanelButton';
import { CreateQuizFormPartTypes } from '@app/web/src/components/CreateQuiz/type';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { roundUpWithoutDecimal } from '@app/web/src/utils/number';
import {
  ChatHashtag as ChatHashtagIcon,
  ProfilePlaylist as ProfilePlaylistIcon,
} from '@front/icon';
import {
  BaseLayoutAction,
  BaseLayoutRightPanel,
  BaseLayoutScrollContainer,
  EmphasizeButton,
  NumberCard,
  useBaseRightPanel,
} from '@front/ui';
import { useExamSectionTags, useHashtagDetail } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { numberFormat } from '@lib/web/utils';

import useQuizHashtagAndEmojiActions from '../hooks/useQuizHashtagAndEmojiActions';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    px: { xs: 2.5, md: '12px' },
    position: 'relative',
  },
  title: { mb: 2, alignItems: 'flex-start' },
  infoCards: { display: 'grid', gap: 2 },
  layoutAction: {
    px: { xs: 2.5, md: '12px' },
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 1,
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
      whiteSpace: 'nowrap',
      '& .em-button-base': {
        px: 0,
      },
    },
  },
  card: {
    minHeight: 'unset',
  },
};

const disabledParts = ['topics', 'difficulty'] as CreateQuizFormPartTypes[];

export default function HashtagDetailPanel() {
  const { t } = useTranslation('analytics');
  const { getRightParams, rightPanelTarget } =
    useBaseRightPanel<GlobalPanelParams>();
  const { title, tagId } = getRightParams(GlobalPanelKeys.GlobalHashtagsDetail);

  const { sectionId } = useCurrentIaClub();
  const { data } = useHashtagDetail({
    tagId: tagId as string,
    sectionId,
  });
  const { goPlaylistPage } = useQuizHashtagAndEmojiActions();

  const { data: examTagsData } = useExamSectionTags(sectionId);
  const tagData = examTagsData?.data.items.find((tag) => tag.id === tagId);

  let mistakeRatio = 0;
  let overtimeRatio = 0;
  if (data?.data.questionCount) {
    mistakeRatio = numberFormat(
      roundUpWithoutDecimal(
        (data.data.mistakeCount / data.data.questionCount) * 100
      )
    );
    overtimeRatio = numberFormat(
      roundUpWithoutDecimal(
        (data.data.overtimeCount / data.data.questionCount) * 100
      )
    );
  }

  return (
    <BaseLayoutRightPanel
      titleIcon={<ChatHashtagIcon width="16" height="16" />}
      title={title}
      inactive={rightPanelTarget !== GlobalPanelKeys.GlobalHashtagsDetail}
    >
      <Box sx={styles.wrapper}>
        <BaseLayoutScrollContainer sx={styles.main}>
          <Box sx={styles.infoCards}>
            <NumberCard
              sx={styles.card}
              title={t('hashtag.RHS.question.title', 'Total Questions Taken')}
              titleIcon="ChatQuestionList"
              loading={!data}
            >
              <NumberCard.Value>{data?.data.questionCount}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.question.description', 'Questions')}
              </NumberCard.Description>
            </NumberCard>
            <NumberCard
              title={t('hashtag.RHS.mistake.title', 'Mistakes')}
              titleIcon="OtherWarning"
              loading={!data}
            >
              <NumberCard.Value suffix=" %">{mistakeRatio}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.mistake.description', 'Since Joining')}
              </NumberCard.Description>
            </NumberCard>
            <NumberCard
              title={t('hashtag.RHS.overtime.title', 'Overtime')}
              titleIcon="TestClock"
              loading={!data}
            >
              <NumberCard.Value suffix=" %">{overtimeRatio}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.overtime.description', 'Since Joining')}
              </NumberCard.Description>
            </NumberCard>
          </Box>
        </BaseLayoutScrollContainer>
        <BaseLayoutAction sx={styles.layoutAction}>
          <EmphasizeButton
            variant="outlined"
            onClick={goPlaylistPage}
            prefixIcon={<ProfilePlaylistIcon />}
          >
            {t('hashtag.RHS.btn.view', 'View Playlist')}
          </EmphasizeButton>
          <CreateQuizPanelButton
            withPanel
            panelKeyPrefix={GlobalPanelKeys.GlobalHashtagsDetail}
            tagSource={tagData ? [tagData] : undefined}
            defaultValues={{
              tags: tagData ? [tagData.code] : undefined,
            }}
            loading={!tagData && !!tagId}
            disabledParts={disabledParts}
          >
            {t('hashtag.RHS.btn.start', 'Start')}
          </CreateQuizPanelButton>
        </BaseLayoutAction>
      </Box>
    </BaseLayoutRightPanel>
  );
}
