import { Box, Skeleton } from '@mui/material';
import { Icon } from '@front/ui';
import { LeagueTier } from '@lib/web/apis';
import { getLeagueBadgeIconName, LEAGUE_TIERS } from '@lib/web/utils';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/free-mode';

import 'swiper/css';

const styles = {
  root: {
    '& .badges-wrapper': {
      alignItems: 'center',
    },
    '& .badge-item': {
      height: '100%',
      width: 'fit-content !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  skeleton: {
    height: 84,
    display: 'flex',
    gap: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

function LeagueBadgesSkeleton() {
  return (
    <Box sx={styles.skeleton}>
      <Skeleton width={48} height={48} variant="circular" />
      <Skeleton width={48} height={48} variant="circular" />
      <Skeleton width={48} height={48} variant="circular" />
      <Skeleton width={48} height={48} variant="circular" />
      <Skeleton width={48} height={48} variant="circular" />
    </Box>
  );
}

type LeagueBadgesProps = {
  currentTier?: LeagueTier;
};

export default function LeagueBadges({ currentTier }: LeagueBadgesProps) {
  if (!currentTier) return <LeagueBadgesSkeleton />;

  return (
    <Box sx={styles.root}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={8}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode]}
        wrapperClass="badges-wrapper"
        initialSlide={currentTier - 3}
      >
        {LEAGUE_TIERS.map((tier, index) => (
          <SwiperSlide key={index} className="badge-item">
            <Icon
              name={getLeagueBadgeIconName(
                currentTier >= index + 1 ? tier : 'Locked'
              )}
              size={currentTier >= index + 1 ? 84 : 48}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
