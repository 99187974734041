import { ButtonProps, SxProps, TypographyProps } from '@mui/material';
import { NumberCardProps } from '@front/ui';
import { IaAction, IaRichText } from '@lib/ia/src/core/types';

export type CtaButton = {
  type: 'button';
  icon?: string;
  label: string;
  color?: 'default' | 'success' | 'error';
  action?: IaAction;
  variant?: 'filled' | 'outlined';
  buttonSx?: ButtonProps['sx'];
  HTMLAttributes?: Record<string, any>;
  metadata?: Record<string, any>;
};

export type CtaBigCardNumber = Pick<
  NumberCardProps,
  | 'title'
  | 'subtitle'
  | 'borderLeft'
  | 'disabled'
  | 'selected'
  | 'gradient'
  | 'singleValue'
> & {
  type: 'bigCardNumber';
  titleIcon?: string; // NumberCardProps: ReactNode
  actionIcon?: string; // NumberCardProps: ReactNode
  action?: IaAction;
  metadata?: Record<string, any>;

  tooltip?: {
    content?: string;
    title?: string;
    titleIcon?: string;
  };

  value?: {
    content: string | number;
    prefix?: string;
    suffix?: string;
    variant?: 'number' | 'currency';

    prefixType?: 'string' | 'icon';
    suffixType?: 'string' | 'icon';
  };

  description?: {
    content: string | number;
  };
};

export type CtaCustomize = {
  type: 'customize';
  value: string;
  metadata?: Record<string, any>;
};

export type NotificationCtaConfig = CtaButton | CtaBigCardNumber | CtaCustomize;

export interface NotificationItemBaseIcon {
  bgColor?: string;
  color?: string;
  size?: number;
}

export type NotificationItemSimpleIcon = NotificationItemBaseIcon & {
  name: string;
};

export type NotificationItemAvatarIcon = NotificationItemSimpleIcon & {
  avatarUrl: string;
  display: string;
};

export type NotificationIconConfig =
  | NotificationItemSimpleIcon
  | NotificationItemAvatarIcon;

export interface ContentItemBase {
  key?: string;
  id?: string;
  type?: string;
}

export type NotificationItemConfig = ContentItemBase & {
  type: 'notification';
  name: string;
  content: string;
  sentTime: string;
  icon: NotificationIconConfig;
  cta?: NotificationCtaConfig[];
  helperText?: string;
};

export type MessageItemAction = IaAction & {
  metadata?: Record<string, any>;
};

export type MessageItemEvent<M = Record<string, any>> = IaAction & {
  metadata?: M;
};

export enum AgentComposingStatus {
  NotStarted = 'not-started',
  Thinking = 'thinking',
  Composing = 'composing',
  Completed = 'completed',
}

export enum AgentMaterialProcessStatus {
  File = 'reading-file',
  Image = 'reading-image',
  Paragraph = 'reading-paragraph',
}

export type MessageItemConfig<M = Record<string, any>> = ContentItemBase & {
  type: 'message';
  sender:
    | {
        type: 'avatar';
        name: string;
        avatarUrl: string;
        badge?: 'private';
        indicators?: { icon: string; name: string; sx?: SxProps }[];
      }
    | {
        type: 'icon';
        name: string;
        icon: string;
        color?: string;
        bgColor?: string;
        size?: number;
        indicators?: { icon: string; name: string; sx?: SxProps }[];
      };
  content: IaRichText<M>;
  contentVariant?: TypographyProps['variant'];
  sentTime: string;
  lastActivityTime?: string;
  /**
   * replies means how many replies for this message
   */
  showReplyThread?: boolean;
  replies?: number;

  /**
   * replyDivider showing how many replies for this channel (thread)
   */
  showReplyDivider?: boolean;
  replyDividerReplies?: number;

  members?: {
    name: string;
    avatarUrl: string;
    indicator?: string;
  }[];
  helperText?: string;
  clickItemAction?: MessageItemAction;
  clickSenderAction?: MessageItemAction;
  viewThreadAction?: MessageItemAction;
  eventMenuClassName?: string;
  events?: MessageItemEvent[];
  actionBarEvents?: MessageItemEvent[];
  dateDividerTime?: string;
  readState?: {
    isUnread: boolean;
    isNewThread: boolean;
  };
  previousMessageProps?: {
    dateDividerTime?: string;
    readState?: {
      isUnread: boolean;
      isNewThread: boolean;
    };
  };
  cta?: NotificationCtaConfig[];
  agentComposingStatus?: AgentComposingStatus;
  agentMaterialProcessStatus?: AgentMaterialProcessStatus;
  agentMaterialFileName?: string;
  enableTypingEffect?: boolean;
  showTypingEffect?: boolean;
};

export type DividerItemConfig = ContentItemBase & {
  type: 'divider';
  content: string;
  textAlign?: 'center' | 'left' | 'right';
};

export type CustomizeItemConfig = ContentItemBase & {
  type: 'customize';
  value: string;
  metadata?: Record<string, any>;
};

export type SkeletonItemConfig = ContentItemBase & {
  type: 'skeleton';
};

export type ChannelContentItemConfig =
  | NotificationItemConfig
  | MessageItemConfig
  | DividerItemConfig
  | CustomizeItemConfig
  | SkeletonItemConfig;

export interface ChannelLayoutConfig {
  layout: 'channel-layout';
  items: ChannelContentItemConfig[];
  settings?: {
    reverseItems?: boolean;
    size?: 'md' | 'sm';
    hoverDisabled?: boolean;
  };
}

export interface ChannelLayoutLoadingConfig {
  layout: 'channel-layout-skeleton';
  settings?: {
    count?: number;
  };
}
