import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { DotLoadingIcon, LoadingIcon } from '@front/ui';
import RichText from '@lib/ia/src/components/RichText';
import TypingEffect from '@lib/ia/src/layouts/ChannelLayout/components/ContentItem/MessageItem/components/TypingEffect';
import {
  AgentComposingStatus,
  AgentMaterialProcessStatus,
  MessageItemConfig,
} from '@lib/ia/src/layouts/ChannelLayout/types';

type MessageItemProps = MessageItemConfig;

const styles = {
  loading: {
    display: 'flex',
    gap: 1,
    '& svg': {
      color: 'primary.light',
    },
  },
};

const LoadingMessage = ({ text }: { text?: string }): JSX.Element => {
  return (
    <Box sx={styles.loading}>
      <LoadingIcon />
      {text}
    </Box>
  );
};

export type ThreadAgentMessageProps = {
  content: MessageItemProps['content'];
  agentComposingStatus: MessageItemProps['agentComposingStatus'];
  agentMaterialProcessStatus: MessageItemProps['agentMaterialProcessStatus'];
  agentMaterialFileName: MessageItemProps['agentMaterialFileName'];
  showTypingEffect: 'true' | 'false' | 'undefined';
};

export default function ThreadAgentMessage({
  content,
  agentComposingStatus,
  agentMaterialProcessStatus,
  agentMaterialFileName,
  showTypingEffect = 'false',
}: ThreadAgentMessageProps) {
  const { t } = useTranslation('thread');

  // Text
  if (
    agentMaterialProcessStatus === AgentMaterialProcessStatus.Paragraph &&
    agentComposingStatus !== AgentComposingStatus.Completed
  ) {
    return <DotLoadingIcon />;
  }

  // Image
  if (
    agentMaterialProcessStatus === AgentMaterialProcessStatus.Image &&
    agentComposingStatus !== AgentComposingStatus.Completed
  ) {
    if (agentComposingStatus === AgentComposingStatus.NotStarted) {
      return (
        <LoadingMessage
          text={`${t(
            'message.agent.reading',
            'Reading'
          )} "${agentMaterialFileName}"`}
        />
      );
    }
    if (
      agentComposingStatus === AgentComposingStatus.Thinking ||
      agentComposingStatus === AgentComposingStatus.Composing
    ) {
      return <LoadingMessage text={t('message.agent.search.knowledge')} />;
    }
  }

  // File
  if (
    agentMaterialProcessStatus === AgentMaterialProcessStatus.File &&
    agentComposingStatus !== AgentComposingStatus.Completed
  ) {
    if (agentComposingStatus === AgentComposingStatus.NotStarted) {
      return (
        <LoadingMessage
          text={`${t(
            'message.agent.reading',
            'Reading'
          )} "${agentMaterialFileName}"`}
        />
      );
    }
    if (
      agentComposingStatus === AgentComposingStatus.Thinking ||
      agentComposingStatus === AgentComposingStatus.Composing
    ) {
      return <LoadingMessage text={t('message.agent.search.knowledge')} />;
    }
  }

  if (showTypingEffect === 'true') {
    return <TypingEffect text={content} variant="chatBody" />;
  }

  return <RichText text={content} variant="chatBody" />;
}
