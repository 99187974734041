import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { Difficulty, ExamMode } from '@lib/web/apis';

export type ChallengerIconListLayoutItemObj = IconListLayoutItemObj<{
  userId: string;
  isFollowing: boolean;
  disabledUpdate?: boolean;
}>;

export type ChallengerIconListLayoutItemObjMap = Record<
  string,
  ChallengerIconListLayoutItemObj
>;

export type TagItemObjMap = Record<
  string,
  GetExamSectionTagsRes | GetQuizHashtagsRes
>;
export type MarkItemObjMap = Record<string, GetIaMarkRhsDefaultViewRes>;

export type Placement = 'center' | 'rhs';

export enum StartQuizBlockerType {
  IncognitoMode = 'i',
  DifficultySuperLevel = 'd',
}

export interface CreateQuizFormValue {
  questionCount: number;
  mode: ExamMode;
  tags: string[];
  marks: string[];
  challengers: string[];
  difficulties: Difficulty[];
  isOpenTimer: boolean;
  isRandomChallengers: boolean;
  isIncognito: boolean;
  randomChallengerCount: number;
  maxAttempt: number;
  deadline: Date;
}

export type CreateQuizFormPartTypes =
  | 'all'
  | 'mode'
  | 'duration'
  | 'difficulty'
  | 'topics'
  | 'challenge'
  | 'challenge-exclude-add-challengers'
  | 'settings';

export type CreateQuizFormStatus = { isDirty: boolean; isSubmitting: boolean };
