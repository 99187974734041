import { useDebounce } from '@front/helper';

import { useAppSelector } from '../redux';

export function useIaSearchValues() {
  const search = useAppSelector((st) => st.search);
  const debouncedSearch = useDebounce(search?.keyword?.trim());

  return {
    debouncedSearch,
    keyword: search?.keyword,
    visible: !!search?.visible,
    hasSearch: !!search?.keyword,
  };
}
